//
// _quilljs.scss
//

.ql-editor {
    text-align: left;

    ol,
    ul {
        padding-left: 1.5em;
        padding-right: 0;
    }

    li:not(.ql-direction-rtl)::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
    }
}

.ql-container {
    font-family: $font-family-base;

    &.ql-snow {
        border-color: $input-border-color;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.ql-bubble {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
}

.ql-toolbar {
    font-family: $font-family-base !important;

    span {
        outline: none !important;
        color: var(--#{$prefix}body-color);

        &:hover {
            color: var(--#{$prefix}primary) !important;
        }
    }

    &.ql-snow {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-color: $input-border-color;

        .ql-picker.ql-expanded {
            .ql-picker-label {
                border-color: transparent;
            }
        }

        .ql-picker-options {
            box-shadow: $dropdown-box-shadow;
            border-radius: $dropdown-border-radius;
        }
    }
}

.ql-snow {

    .ql-stroke,
    .ql-script,
    .ql-strike svg {
        stroke: var(--#{$prefix}body-color);
    }

    .ql-fill {
        fill: var(--#{$prefix}body-color);
    }

    .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) {
        svg {
            right: 0;
            left: auto;
        }
    }

    .ql-picker {
        &.ql-expanded .ql-picker-label {
            color: var(--#{$prefix}secondary-color);
        }
    }
}

.ql-snow {
    .ql-picker-options {
        background-color: var(--#{$prefix}secondary-bg);
        border-color: var(--#{$prefix}border-color) !important;
    }
}