// 
// reboot.scss
//

html {
    position: relative;
    min-height: 100%;
}

label {
    font-weight: $font-weight-medium;
    margin-bottom: $form-label-margin-bottom;
}
b,
strong {
  font-weight: $font-weight-semibold;
}

// blockquote

.blockquote {
    padding: 10px 20px;
    border-left: 4px solid var(--#{$prefix}border-color);
}

.blockquote-reverse {
    border-left: 0;
    border-right: 4px solid var(--#{$prefix}border-color);
    text-align: right;
}

// container

:is(.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl) {
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
}

// row
.row>*{
    position: relative;
}